/*
* 업무구분: 업무공통
* 화면 명: 통합인증 로그인 메인
* 화면 설명: 통합인증 ID/PW로 로그인을 하는 페이지
* 화면 접근권한: N/A
*/
<template>
  <ur-page-container title="삼성생명 통합인증" class="msp" :show-title="false" type="page" action-type="none" v-show="checkShow">
    <ur-box-container direction="column" alignV="start" class="msp-bc-wrap">  
    <ur-box-container direction="column" alignV="start">
      <mo-validate-watcher ref="vWatcher">
        <ur-box-container alignV="start" componentid="ur_box_container_001" direction="column" class="ns-login-area">
            <div class="ns-to-ttl">삼성생명 통합인증</div> 

            <!-- 사용자사번 -->
            <mo-text-field
              v-if="lv_UserEnoAsterickFlag"
              v-model="lv_UserEno"
              :rules="lv_validateRule_UserEno"
              underline
              clearable
              placeholder="아이디 (FC코드 또는 사번)"
              @blur="fn_UserEnoInputFocusOut"
              type="number"
              class="login-id"
              maxlength="10"/>
            <mo-text-field
              v-else
              v-model="lv_UserEnoAsterick"
              :rules="lv_validateRule_UserEno"
              underline
              clearable
              placeholder="아이디 (FC코드 또는 사번)"
              @focus="fn_UserEnoInputFocusOn"
              class="login-id"
              maxlength="10"/>
            <!-- 사용자사번 -->
            
            <!-- 비밀번호 -->
            <m-trans-key-input
              v-if="lv_mobileYn"
              v-model="lv_UserPswd"
              class="login-password"
              @mask-type="fn_getMaskType"
              type="qwerty"
              placeholder="비밀번호"
              ref="mtrans"
              :start="'0'"
              :end="'-1'">
            </m-trans-key-input>
            <mo-text-field
              v-else
              v-model="lv_UserPswd"
              :rules="lv_validateRule_UserPswd"
              underline
              placeholder="비밀번호"
              password class="login-password"/>
            <!-- 비밀번호 -->
          
          <mo-checkbox v-model="lv_UserEnoSaveFlag" class="ns-check">아이디 저장</mo-checkbox>
          <mo-button componentid="mo_button_001" color="primary" shape="border" size="medium" class="ns-btn-round" @click="fn_Salt">로그인</mo-button>

          <ur-box-container alignV="start" componentid="ur_box_container_002" direction="column" class="ns-login-box pb30">
            <mo-button componentid="mo_button_002" color="primary" shape="border" size="medium" class="login-box-btn" @click="fn_OpenUntyBottomSheet">통합인증 로그인 관리</mo-button>            
            <!-- 간편인증 블럭 처리 20220827  다시 복구 20220830-->
            <!-- <mo-button v-show="!lv_isIOS && (lv_RegistedPin || lv_RegistedBio)" componentid="mo_button_003" color="primary" shape="border" size="medium" class="login-box-btn" @click="fn_OpenMSPBC611P">다른 방법 로그인</mo-button> -->
            <mo-button v-show="lv_RegistedPin || lv_RegistedBio" componentid="mo_button_003" color="primary" shape="border" size="medium" class="login-box-btn" @click="fn_OpenMSPBC611P">다른 방법 로그인</mo-button>
          </ur-box-container>

        </ur-box-container>
      </mo-validate-watcher>


      <!-- 통합인증 이동 -->
      <!-- <div class="ns-bottom-sheet only-btn padding shwDimm"> -->
        <!-- <mo-bottom-sheet ref="bottomSheetUntyAthnt">
          <template v-slot:action>
            <ur-box-container alignV="start" componentid="ur_box_container_002" direction="column" class="ns-btn-relative noshadow">
              <div class="relative-div">
                <mo-button componentid="mo_button_002" color="primary" shape="border" size="medium" class="ns-btn-round white"
                  @click="fn_OpenUntyAthntPage('findId')">아이디 찾기</mo-button>
                <mo-button componentid="mo_button_002" color="primary" shape="border" size="medium" class="ns-btn-round white"
                  @click="fn_OpenUntyAthntPage('resetPw')">비밀번호 초기화</mo-button>
              </div>
            </ur-box-container>
          </template>
        </mo-bottom-sheet> -->
        <!-- Sub 메뉴영역 -->
        <mo-bottom-sheet ref="bottomSheetUntyAthnt" class="ns-main-sheets">
          <template v-slot:title>통합인증 로그인 관리</template>
          <div class="ns-btn-close" @click="fn_CloseSubMeun"></div>
          <div class="ns-main-menu" style="padding-top: 12px;">
            <ul>
              <li v-for="(item, index) in lv_MenuList" :key="index" @click="fn_OpenUntyAthntPage(item.param)" style="height: 36px; line-height:36px; padding:0 18px;">{{item.label}}</li>
            </ul>
          </div>
        </mo-bottom-sheet>
        <!-- Sub 메뉴영역 //-->        
      <!-- </div> -->

      <ur-box-container v-if="!this.$commonUtil.getIsIOS()" alignV="start" componentid="" direction="column" class="pl24 pr24 mb50">
        <ur-box-container alignV="start" componentid="" direction="column" class="home_profile--box ty2">
          <div class="profile_box_wrap">
            <ur-box-container class="bg_profile--lightblue">
              <div class="full fwb fs17rem crTy-blue3">
                사랑On을 더 빠르게 사용하려면?
              </div>
              <p class="fs17rem mt5 mb20"></p>
              <!-- <p class="fs17rem mt5 mb20">테스트입니다.</p>             -->
              <div class="profile_btn_wrap ">
                <mo-button class="" @click="fn_initMemory">디바이스 케어 바로가기</mo-button>
              </div>
               <p class="fs17rem mt5 mb20"></p>
              <p class="fs17rem mt5 mb20">※ ‘지금 최적화’ 버튼을 누르고, 사랑On을 다시 켜주세요!</p>
            </ur-box-container>
          </div>
        </ur-box-container>        
        </ur-box-container>
      </ur-box-container>
    </ur-box-container> 
  </ur-page-container>
</template>
<script>
import Msg from '@/systems/webkit/msg/msg'
// import bcConstants from '@/config/constants/bcConstants.js'
import MSPBottomAlert from '@/ui/_cmm/components/MSPBottomAlert'
import moment from 'moment'

/***********************************************************************************
 * 공통 라이브러리 INCLUDE 영역	                                                   *
 ***********************************************************************************/
export default {
  /***********************************************************************************
   * Vue 파일 속성 정의 영역	                                                       *
   ***********************************************************************************/
  name: "MSPBC600M",
  screenId: "MSPBC600M",
  /***********************************************************************************
   * Life Cycle 함수 정의 영역	                                                     *
   ***********************************************************************************/
  beforeCreate () {
    console.log('##### beforeCreate #####')
    
    window.fdpbridge.exec('splashHidePlugin', {},
      (success) => { console.log('[splashHidePlugin] success : ' + success) },
      (error) => { console.log('[splashHidePlugin] error : ' + error) }
    )
  },
  async created() {
    console.log('##### created #####')
    let lv_Vm = this
    // 브라우저 접근, 모바일 여부 확인
    this.lv_mobileYn = this.$commonUtil.checkApp()
    localStorage.removeItem('fofVstYn')
    localStorage.removeItem('user_mongo_yn')

    // 로그인 시 기존 디바이스 토큰 삭제 (전화받기용)
    localStorage.removeItem('device_token')
    localStorage.removeItem('device_token_time')
    // 디바이스 토큰 초기화
    if (this.lv_mobileYn) {
        window.fdpbridge.exec('AsyncStorageSetPlugin', {key: 'deviceToken', data: ''}, function (result) {}, function (result) {})        
    }

    // window.fdpbridge.addEventListener('reStartWebview', (data) => {
    //   // 로그인 사용자 정보 data: { uri: .....'' }
    //   console.log('##### reStartWebview tssAccess addeventListener 등록 #####')
    //   lv_Vm.$router.push({name: 'tssAccess', params: { reStartWebview : true }}).catch(()=>{})
    // })

    window.fdpbridge.addEventListener('tssAccess', (data) => {
      // 로그인 사용자 정보 data: { uri: .....'' }
      console.log('##### tssAccess addeventListener 등록 #####')
      window.vue.getInstance('router').push({name: 'tssAccess'}).catch(()=>{})
    })

    if (this.lv_mobileYn) {
      window.fdpbridge.exec('getTssAccessDataPlugin', '', (msg) => {
          console.log('MSPBC600M getTssAccessDataPlugin: ' + JSON.stringify(msg))
          let result = JSON.parse(msg.data)
          console.log('result.srnId = ' + result.srnId)
          if (result.srnId !== undefined && result.srnId !== null && result.srnId !== '') {
            console.log('계속 안보이는 중')
          } else {
            lv_Vm.checkShow = true
          }
      }, (error) => { 
        console.log('[getTssAccessDataPlugin] error : ' + error)
        lv_Vm.checkShow = true
      })
    } else {
      lv_Vm.checkShow = true
    }

    // backKey event 등록
    window.vue.getWkEvtHandler().$on(Msg.DEVICE.BACK_KEY, this.fn_ExitApp)
    this.$BottomManager.fn_SetBottomVisible(false)

    // 로그인 사용자 정보
    this.lv_UserEnoSaveFlag = localStorage.getItem('userEnoSaveFlag1') ? (localStorage.getItem('userEnoSaveFlag1') === 'true' ? true : false) : false // 사용자사번 재사용 여부
    this.lv_UserEno = (localStorage.getItem('userEno') && this.lv_UserEnoSaveFlag) ? localStorage.getItem('userEno') : '' // 사용자사번
    console.log('userEno localStorage = '+ localStorage.getItem('userEno') )
    console.log('lv_UserEno = '+ this.lv_UserEno )
    console.log('lv_UserEnoSaveFlag = ' + this.lv_UserEnoSaveFlag )
    this.fn_MaskingUserEno() // 사용자사번 마스킹 처리
    this.lv_LoginType = localStorage.getItem('loginType') ? localStorage.getItem('loginType') : '' // 최종로그인 방법

    // VestPin 초기 설정 세팅 및 로드
    this.fn_LoadVestPin()
    // VestPin 사용자 등록 정보 조회
    // this.fn_GetVestPinUserInfo()
    this.lv_IsDeleteWebView = await this.fn_VersionCheck()
    this.fn_SysAnucCall()
   
    // iOS 여부 확인
    if (this.$commonUtil.getIsIOS()) {
      localStorage.setItem('osType', 'ios')
      this.$bcUtil.getIOSBioType() // iOS 생체인증 타입 세팅
    } else {
      localStorage.setItem('osType', 'aos')
      localStorage.setItem('iOSBioType', 'touchID') // 안드로이드 바이오 타입
    }
  },
  mounted () {
    this.$bizUtil.insSrnLog('MSPBC600M')
  },  
  beforeDestroy () {
    console.log('##### beforeDestroy #####')
    // bottom bar 노출
    this.$BottomManager.fn_SetBottomVisible(this.Iv_isSysInfo==false?true:false)

    window.vue.getWkEvtHandler().$off(Msg.DEVICE.BACK_KEY, this.fn_ExitApp)
  },
  /***********************************************************************************
   * 화면변수 선언 영역	                                                             *
   ***********************************************************************************/
  data() {
    return {
      lv_UserEno: '',
      lv_validateRule_UserEno: [
        v => !!v || '아이디(사번)를 입력해 주세요.'
      ],
      lv_validateRule_UserPswd: [
        v => !!v || '비밀번호를 입력해 주세요.'
      ],
      lv_UserEnoAsterick: '',
      lv_UserEnoAsterickFlag: false,
      lv_UserPswd: '',
      lv_UserEnoSaveFlag: false,
      lv_LoginType: '',
      lv_RegistedPin: false,
      lv_RegistedBio: false,
      lv_env: process.env.NODE_ENV,
      lv_maskType: '',
      lv_mobileYn: false,
      lv_MenuList: [
        { label: '비밀번호 초기화', param: 'resetPw'},
        { label: '아이디 잠금 해제', param: 'unlockId'},
        { label: '최초 로그인', param: 'initialLogin'},
        { label: '아이디 찾기', param: 'findId'}        
      ],
      lv_AlertPop: '',// bottom alert component
      lv_MsgLogoutConfirm: '사랑On 앱을 종료하시겠습니까?',
      lv_todayTime: '',
      Iv_SysInfo:{},
      Iv_isSysInfo:false,
      checkShow: true,
      lv_rslt: false,
      lv_appApiCheckYn: 'Y',
      lv_IsDeleteWebView: false,
      lv_TargetDate: '2024-06-25' // 오픈일자
    }
  },
  /***********************************************************************************
   * watch 정의 영역
   ***********************************************************************************/
  watch: {
    // clearable event 처리
    lv_UserEnoAsterick (val) {
      if (val === '') {
        this.lv_UserEno = ''
      }
    }
  },
  /***********************************************************************************
   * 사용자 함수 정의 영역                                                           *
   ***********************************************************************************/
  methods: {

    /******************************************************************************
    * Function명 : fn_SysAnucCall
    * 설명       : 시스템차단공지 여부 체크 
    ******************************************************************************/
    fn_SysAnucCall () {
      let lv_Vm = this

      this.post(this, null, 'txTSSBC21S1', 'S')
          .then(function (response) {
            lv_Vm.Iv_SysInfo = response.body
            if(lv_Vm.Iv_SysInfo !== null && lv_Vm.Iv_SysInfo.isSysInfo){
              console.log('**** fn_SysAnucCall >> Iv_SysInfo.isSysInfo  >>>>>>>>>>>>>' + lv_Vm.Iv_SysInfo.isSysInfo )

              lv_Vm.lv_appApiCheckYn = lv_Vm.Iv_SysInfo.useYn
              lv_Vm.$router.push( {name: 'MSPBC220M', params: {'isSysInfo':lv_Vm.Iv_SysInfo.isSysInfo,'bcAnucMngInfo':lv_Vm.Iv_SysInfo.bcAnucMngInfo}} ).catch(()=>{})
              lv_Vm.Iv_isSysInfo = lv_Vm.Iv_SysInfo.isSysInfo
            } else {
              if (lv_Vm.Iv_SysInfo !== null && lv_Vm.Iv_SysInfo.useYn !== null){
                lv_Vm.lv_appApiCheckYn = lv_Vm.Iv_SysInfo.useYn
              } else {
                lv_Vm.lv_appApiCheckYn = 'Y'
              }

              if (lv_Vm.lv_IsDeleteWebView) return
              lv_Vm.fn_GetVestPinUserInfo()
            }    
          })
          .catch(function (error) {
            window.vue.error(error)
          })
    },
    fn_Salt () {
      let lv_Vm = this
      let t
      if (lv_Vm.$commonUtil.getIsIOS()) {
        t = 'IOS'
      } else {
        t = 'AOS'
      }

      // if (process.env.NODE_ENV === 'local') {
      if (!this.lv_mobileYn) {
        lv_Vm.$commonUtil.getLoginServerTime(t).then( function (response) {
          lv_Vm.lv_todayTime = response
          lv_Vm.fn_UntyAthntLogin()
        })
      } else {
        lv_Vm.fn_GetVestPinUserDataInfo()
      }  
      /*
      lv_Vm.$commonUtil.getLoginServerTime(t).then( function (response) {
        lv_Vm.lv_todayTime = response
        lv_Vm.fn_UntyAthntLogin()
      })
      */
    },

    /******************************************************************************
    * Function명 : fn_UntyAthntLogin
    * 설명       : 통합인증 로그인 요청(txTSSBC90P1) 처리
    ******************************************************************************/
    fn_UntyAthntLogin () {
      console.log('##### fn_UntyAthntLogin #####')
      /////
      localStorage.setItem('fidoRegGuideFlag', false)
      localStorage.setItem('isShowFidoRegGuidePopup', false)
      if (this.lv_RegistedPin || this.lv_RegistedBio) this.lv_UserEnoSaveFlag = true
      //////////

      // ID/PW 빈 값 체크
      let ret = this.$refs.vWatcher.validate()
      if (ret !== true) return

      let lv_Vm = this

      let param = {
        userEno: lv_Vm.lv_UserEno,
        pswdEncrVal: lv_Vm.lv_UserPswd,
        encType: lv_Vm.lv_maskType,
        salt: lv_Vm.lv_todayTime
      }
      // 통합인증 로그인 요청
      window.vue.getStore('progress').dispatch('ADD') // txTSSBC90P1 프로그래스바 표시
      lv_Vm.post(lv_Vm, param, 'txTSSBC90P1')
        .then(function (response) {
          if (response.body !== null && response.body !== '') {
            let rtnData = response.body
            
            /* 로그인 성공 */
            if (rtnData.successYn === 'Y') {
              // 신규 사용자 로그인
              // if (lv_Vm.lv_UserEno !== localStorage.getItem('userEno')) {
                /* 기존 사용자 간편인증(PIN, 생체) 등록정보 모두 삭제 */
                // window.vestPIN.removeAllUsers()
                // lv_Vm.$bcUtil.openBottomPopUp(lv_Vm, bcConstants.UNTY_ATHNT_CONFIRM_CASE[4]) // (4)통합인증-로그인 성공
              // 기존 사용자 로그인
              // } else {
                // 간편인증 기등록 여부 확인
                // if (!(lv_Vm.lv_RegistedPin || lv_Vm.lv_RegistedBio)) lv_Vm.$bcUtil.openBottomPopUp(lv_Vm, bcConstants.UNTY_ATHNT_CONFIRM_CASE[4]) // (4)통합인증-로그인 성공
              // }
              
              // 로그인 사용자 정보 저장
              localStorage.setItem('userEno', lv_Vm.lv_UserEno)
              localStorage.setItem('userEnoEnc', rtnData.userEnoEncrVal)
              localStorage.setItem('userEnoSaveFlag1', lv_Vm.lv_UserEnoSaveFlag)
              localStorage.setItem('ownerEno', lv_Vm.lv_UserEno)

              // wifi 초기화 횟수 및 시간 저장
              localStorage.setItem('INIT_WIFI_CONN_DATETIME', '')
              localStorage.setItem('WIFI_BLOCKING_TIME', '')

              //localStorage.setItem('login_type_error', '') //
              localStorage.setItem('user_mongo_yn', 'Y')              
              if (lv_Vm.lv_mobileYn) { // 모바일 App으로 접근시
                window.vue.getStore('progress').dispatch('ADD') // 모바일App으로 접근시, 프로그래스바 표시
                
                if (process.env.NODE_ENV === 'development') {
                  // 디바이스 소유자 정보 저장
                  localStorage.setItem('ownerEno', lv_Vm.lv_UserEno)
                  localStorage.setItem('ownerEnoEnc', rtnData.userEnoEncrVal)
                  localStorage.removeItem('loginType')

                  // 간편인증 등록 유도 flag 추가
                  // if (!(lv_Vm.lv_RegistedPin || lv_Vm.lv_RegistedBio)) localStorage.setItem('fidoRegGuideFlag', true)

                  // JWT 발행 요청, txTSSBC32S1
                  let jwtParam = { userEno: lv_Vm.lv_UserEno, encUserEno: rtnData.userEnoEncrVal, imei: lv_Vm.lv_UserEno, mobslSystemCd : '04' }

                  lv_Vm.$bcUtil.getAccessToken(lv_Vm, jwtParam).then(function () {
                    localStorage.setItem('login_type_error', '') 
                    // 진입 목적 별, 메인 페이지로 이동
                    if (rtnData.expirePwdDate === '') {
                      // 간편인증 등록 유도 flag 추가
                      if (!(lv_Vm.lv_RegistedPin || lv_Vm.lv_RegistedBio)) localStorage.setItem('fidoRegGuideFlag', true)
                      lv_Vm.$MenuManager.fn_InitIntentListener({ userId: rtnData.userEnoEncrVal })
                    } else {
                      lv_Vm.fn_UpdPasswd(rtnData.userEnoEncrVal, rtnData.expirePwdDate, true, lv_Vm.lv_UserEno)
                    }
                  }).catch((e)=>{
                    if (e.error.msg === '초과오류자 입니다. 로그인 관리센터의  아이디 잠김해제 메뉴를 이용하여 주시기 바랍니다.') {
                      lv_Vm.fn_ErrorN03()
                    } else {
                      // lv_Vm.$bizUtil.insAccssTokenErrLog()
                      // lv_Vm.$ToastManager.fn_ShowToast({ text: '[' + e.error.code + '] 잠시 후 다시 사용해주세요. 계속 발생 시 서비스데스크로 문의 바랍니다.(' + e.error.msg + ')'})
                      lv_Vm.$ToastManager.fn_ShowToast({ text: '[' + e.error.code + '] 접속이 지연 되고 있으니, 로그인 버튼을 다시 눌러주세요.(' + e.error.msg + ')'})
                    }
                  })
                } else {        
                  console.log('### 망고바나나호출여부 : ', lv_Vm.lv_appApiCheckYn )
                  // 망고바나나 체크
                  window.vue.getStore('progress').dispatch('ADD') // 망고바나나, 프로그래스바 표시
                  let jsonRst
                  window.fdpbridge.exec('mangobananaPlugin', { userEno: lv_Vm.lv_UserEno, appApiCheckYn: lv_Vm.lv_appApiCheckYn },
                  //window.fdpbridge.exec('mangobananaPlugin', { userEno: lv_Vm.lv_UserEno },
                    (success) => {
                      window.vue.getStore('progress').dispatch('SUB') // 망고바나나, 프로그래스바 제거
                      console.log('[mangobananaPlugin] 본인기기 인증 완료 : ', success)
                      try {
                        jsonRst = JSON.parse(success.data)
                        console.log(jsonRst.result)
                        if (jsonRst.result === undefined) {
                          // jsonRst = {result: true, message: ''}
                          jsonRst = {result: success.data, message: lv_Vm.lv_UserEno}
                        } 
                      } catch (e) {
                        console.log(e)
                        jsonRst = {result: true, message: ''}
                      }                      
                      if (jsonRst.result) { // 본인 디바이스 일 경우,
                        // 디바이스 소유자 정보 저장
                        localStorage.setItem('ownerEno', lv_Vm.lv_UserEno)
                        localStorage.setItem('ownerEnoEnc', rtnData.userEnoEncrVal)
                        localStorage.setItem('user_mongo_yn', 'Y')                        
                        localStorage.removeItem('loginType')

                        // 푸쉬 연계
                        console.log('##### window.fdpbridge.exec(initPushPlugin) #####')
                        window.fdpbridge.exec('initPushPlugin', { cuid: lv_Vm.lv_UserEno },
                          (success) => { 
                            console.log('[initPushPlugin] success : ' + success) 
                          },
                          (error) => { 
                            console.log('[initPushPlugin] error : ' + error) 
                          }
                        )
                        
                        // 간편인증 등록 유도 flag 추가
                        // if (!(lv_Vm.lv_RegistedPin || lv_Vm.lv_RegistedBio)) localStorage.setItem('fidoRegGuideFlag', true)

                        // JWT 발행 요청, txTSSBC32S1
                        let jwtParam = { userEno: lv_Vm.lv_UserEno, encUserEno: rtnData.userEnoEncrVal, imei: lv_Vm.lv_UserEno}

                        lv_Vm.getStore('progress').dispatch('ADD') // getAccessToken, 프로그래스바 표시
                        lv_Vm.$bcUtil.getAccessToken(lv_Vm, jwtParam).then(function () {
                          // 진입 목적 별, 메인 페이지로 이동
                          if (rtnData.expirePwdDate === '') {
                            // 간편인증 등록 유도 flag 추가
                            if (!(lv_Vm.lv_RegistedPin || lv_Vm.lv_RegistedBio)) localStorage.setItem('fidoRegGuideFlag', true)
                            lv_Vm.$MenuManager.fn_InitIntentListener({ userId: rtnData.userEnoEncrVal })
                          } else {
                            lv_Vm.fn_UpdPasswd(rtnData.userEnoEncrVal, rtnData.expirePwdDate, true, lv_Vm.lv_UserEno)
                          }    
                        }).catch((e)=>{
                          if (e.error.msg === '초과오류자 입니다. 로그인 관리센터의  아이디 잠김해제 메뉴를 이용하여 주시기 바랍니다.') {
                            lv_Vm.fn_ErrorN03()
                          } else {
                            // lv_Vm.$bizUtil.insAccssTokenErrLog()
                            // lv_Vm.$ToastManager.fn_ShowToast({ text: '[' + e.error.code + '] 잠시 후 다시 사용해주세요. 계속 발생 시 서비스데스크로 문의 바랍니다.(' + e.error.msg + ')'})
                            lv_Vm.$ToastManager.fn_ShowToast({ text: '[' + e.error.code + '] 접속이 지연 되고 있으니, 로그인 버튼을 다시 눌러주세요.(' + e.error.msg + ')'})
                          }  
                        }).finally(() => {
                          lv_Vm.getStore('progress').dispatch('SUB') // getAccessToken, 프로그래스바 닫기
                        })
                      } else { // 본인 디바이스가 아닐 경우,
                        localStorage.setItem('user_mongo_yn', 'N')

                        if (rtnData.expirePwdDate === '') {
                          lv_Vm.$bcUtil.openFidoPopUpPage(lv_Vm, 'MSPBC641P', { userEno: lv_Vm.lv_UserEno, fidoRegType: '', loginFlag: false,  imei: jsonRst.message})
                        } else {
                          lv_Vm.fn_UpdPasswd(rtnData.userEnoEncrVal, rtnData.expirePwdDate, false, jsonRst.message)
                        }  
                      }
                    }, (error) => {
                      window.vue.getStore('progress').dispatch('SUB') // 망고바나나, 프로그래스바 제거
                      console.log('[mangobananaPlugin] 타인기기 접속 : ', error)

                      // mangobanana 오류 대응
                      let msg = error.data.code || 'MDM에서 오류가 발생하였습니다. 앱을 종료합니다.'
                      lv_Vm.getStore('confirm').dispatch('UPDATE_IS_SYS', true)
                      lv_Vm.getStore('toast').dispatch('ADD', msg)
                    }
                  )
                }
                lv_Vm.getStore('progress').dispatch('SUB') // 모바일 App으로 접근시, 프로그래스바 표시
              } else {
                lv_Vm.$bizUtil.getUserSessionInfo(lv_Vm, lv_Vm.lv_UserEno).then( function () {
                  lv_Vm.$router.push({ name: 'MSPBC002M' }).catch(()=>{})
                })
              }
              
            /* 로그인 실패 */
            } else if (rtnData.successYn === 'N01') {
              lv_Vm.fn_ErrorN01()
            //  비밀번호 시도 횟수(5회) 초과 오류
            } else if (rtnData.successYn === 'N03') {
              // lv_Vm.$bcUtil.openBottomPopUp(lv_Vm, bcConstants.UNTY_ATHNT_CONFIRM_CASE[2]) // (2)통합인증-입력오류 5회 초과 오류
              lv_Vm.fn_ErrorN03()
            // 비밀번호 미일치 오류
            } else if (rtnData.successYn === 'N04') {
              lv_Vm.$ToastManager.fn_ShowToast({ text: '아이디 또는 비밀번호를 다시 확인해주세요.' })
            // 비밀번호 만료 오류
            } else if (rtnData.successYn === 'N05') {
              lv_Vm.fn_ErrorN05()
            } else if (rtnData.successYn === 'N06') {
              lv_Vm.fn_ErrorN06()
            } else if (rtnData.successYn === 'N07') {
              lv_Vm.fn_ErrorN07(rtnData.returnMsg)
            } else if (rtnData.successYn === 'N08') {
              lv_Vm.fn_ErrorN08()
            // 기타 오류
            } else {
              lv_Vm.$ToastManager.fn_ShowToast({ text: '아이디 또는 비밀번호를 다시 확인해주세요.' })
              // lv_Vm.$bcUtil.openBottomPopUp(lv_Vm, bcConstants.UNTY_ATHNT_CONFIRM_CASE[3]) // (3)통합인증-기타시스템 오류
            }
          } else {
            lv_Vm.$ToastManager.fn_ShowToast({ text: '아이디 또는 비밀번호를 다시 확인해주세요.' })
            // lv_Vm.$bcUtil.openBottomPopUp(lv_Vm, bcConstants.UNTY_ATHNT_CONFIRM_CASE[3]) // (3)통합인증-기타시스템 오류
          }
          window.vue.getStore('progress').dispatch('SUB') // txTSSBC90P1 프로그래스바 닫기
        })
        .catch(window.vue.error)
    },
    /******************************************************************************
    * Function명 : fn_LoadVestPin
    * 설명       : VestPin 로드
    ******************************************************************************/
    fn_LoadVestPin () {
      console.log('##### fn_LoadVestPin #####')
      const checkloadScript = () => {
        return window.yt && window.yt.vestPIN
      }
      const setVestPin = () => {
        // VestPin 관련 스크립트 로딩 체크
        if (checkloadScript()) {
          // VestPin 초기 설정 세팅
          this.fn_SetVestPinInitialOption()
        } else {
          setTimeout( function () {
            setVestPin()
          }, 200)
        }
      }
      setVestPin()
    },
    /******************************************************************************
    * Function명 : fn_SetVestPinInitialOption
    * 설명       : VestPin 초기 옵션 세팅
    ******************************************************************************/
    fn_SetVestPinInitialOption () {
      /* 키패드 옵션 */
      var config = {
        mobileMaxWidth: 520, // 값 수정시 모바일 가로모드에서 비정상적으로 보일 수 있음
        keypadType: 'number', /* 키패드 타입. 쿼티: 'qwerty', 숫자: 'number', 패턴: 'pattern' */
        easyReadability: false, /* 키 배열 mix 옵션 */
        // keypadURL: {
        //   number: 'vikie_number.html',
        //   qwerty: 'vikie_qwerty.html',
        //   pattern: 'vikie_pattern.html'
        // },
        zIndex: 2000, /* z-index 설정 옵션 */
        figureLimitations: 6, /* PIN패드 사용 자리수 */
        initValueRearrange: false, /* 재배열 시 기존 값 초기화 옵션 / true: 초기화 o, false: 초기화 x */
        convertKeyOptions: { interval: 5 }, /* 키값 치환 옵션 */
        titleDisplay: true, /* 키패드 닫기 버튼 활성화 옵션 */
        /* 키값 체크 옵션 */
        keyCheckOptions: {
          flag: 3, /* 0: pin 유효성체크 안함, 1: 동일 값 체크, 2: 연속된 값 체크, 3: 동일 값+연속된 값 체크 */
          maxLimit: 2 /* 동일 값 & 연속된 값 최대갯수 (default: 2 이상) */
        },
        /* 숫자 키패드 숫자 svg 커스텀 */
        centerBtns: {
          number: [
            '/static/vestpin/svg/0.svg', '/static/vestpin/svg/1.svg', '/static/vestpin/svg/2.svg', '/static/vestpin/svg/3.svg', '/static/vestpin/svg/4.svg',
            '/static/vestpin/svg/5.svg', '/static/vestpin/svg/6.svg', '/static/vestpin/svg/7.svg', '/static/vestpin/svg/8.svg', '/static/vestpin/svg/9.svg'
          ]
        },
        /* 숫자 모바일 하단 버튼 커스터마이징 */
        bottomBtns: {
          security: { show: false, order: 1, size: 1 },
          backspace: { show: true, order: 1, size: 1 },
          rearrange: { show: false, order: 1, size: 1 },
          complete: { show: true, order: 2, size: 1, svg: '/static/vestpin/svg/confirm.svg' }
        },
        /* 공백 버튼 추가 유무 */
        blankBtns: false,
        /* seed 추출 옵션 */
        extractSeed: {
          flag: false, /* 기능 사용 유무 */
          url: location.href /* 공통저장소 도메인 주소 */
        }
      }

      // vestPIN 초기화
      window.vestPIN = null

      /* iOS의 경우, 생체를 사용하기 때문에 앱저장소 사용
         AOS의 경우, PIN만 사용하기 때문에 로컬저장소 사용 */
      let storageType = 'APP' // this.$commonUtil.getIsIOS() ? 'APP' : ''

      /* VestPIN Client 설정 */
      var options = {
        serveletURL: location.origin + '/vestpin/pin', /* 서버 URL */
        /* 저장소 관련 설정 */
        storage: {
          type: storageType, /* '': 로컬저장소, 'APP': 앱저장소, 'HIBISCUS': 공통저장소 */
          /* 앱 저장소 설정 */
          APP: { serviceName: 'sslife' },	// 앱저장소 이름
          HIBISCUS: {} // 공통저장소
        },
        /* 블럭체인 관련 설정 */
        blockChain: {
          challenge: false // true: 블럭체인 인증시 사용, false: 미사용
        }
      }

      window.yt.vikie.load(config, function (res) {
        try {
          window.vestPIN = new window.yt.vestPIN.Client(options.serveletURL, function (epin) {
            return res(epin.count, epin.cipher)
          }, options)
        } catch (e) {
          console.log(e)
        }
      }, function (err) {
        console.log('vikie got error: \n' + err)
      })

      window.yt.vikie.addEventHandler('close', function (custom) {
        console.log('##### close #####')
      })

      window.yt.vikie.addEventHandler('keypress', function (custom) {
        console.log('##### keypress #####')
        custom.value += '*'
      })

      window.yt.vikie.addEventHandler('backspace', function (custom) {
        console.log('##### backspace #####')
        custom.value = custom.value.substring(1)
      })
    },
    /******************************************************************************
    * Function명 : fn_GetVestPinUserInfo
    * 설명       : VestPin 사용자 등록 정보 조회 및 최종 로그인 방법 페이지 이동
    ******************************************************************************/
    fn_GetVestPinUserInfo () {
      console.log('##### fn_GetVestPinUserInfo #####')
      console.log('## fn_GetVestPinUserInfo >> lv_Vm.Iv_isSysInfo=' + this.Iv_isSysInfo)
      const checkloadScript = () => {
        return window.vestPIN
      }
      const getUserInfo = () => {
        // VestPin 관련 스크립트 로딩 체크
        if (checkloadScript()) {
          // VestPin 등록정보 조회
          this.$bcUtil.getVestPinUserList(this.lv_UserEno)
            .then((result) => {
              console.log('fn_GetVestPinUserInfo =>>> ')
              console.log(result)
              this.lv_RegistedPin = result.pin
              this.lv_RegistedBio = result.bio
              console.log('lv_RegistedPin = ' + this.lv_RegistedPin)
              console.log('lv_RegistedBio = ' + this.lv_RegistedBio)
              if (this.lv_LoginType === 'pin' && result.pin) {
                // 최종 로그인 방법(pin) 페이지 호출
                this.$bcUtil.openFidoPopUpPage(this, 'MSPBC620P', {})
              } else if (this.lv_LoginType === 'bio' && result.bio) {
                // 최종 로그인 방법(bio) 페이지 호출
                this.$bcUtil.openFidoPopUpPage(this, 'MSPBC630P', {})
              }
            })
            .catch(window.vue.error)
        } else {
          setTimeout( function () {
            getUserInfo()
          }, 200)
        }
      }
      getUserInfo()
    },
    fn_GetVestPinUserDataInfo () {
      console.log('##### fn_GetVestPinUserDataInfo #####')
      console.log('## fn_GetVestPinUserDataInfo >> lv_Vm.Iv_isSysInfo=' + this.Iv_isSysInfo)
      const checkloadScript = () => {
        return window.vestPIN
      }
      const getUserInfo = () => {
        // VestPin 관련 스크립트 로딩 체크
        if (checkloadScript()) {
          // VestPin 등록정보 조회
          this.$bcUtil.getVestPinUserList(this.lv_UserEno)
            .then((result) => {
              console.log('fn_GetVestPinUserInfo =>>> ' + this.lv_UserEno)
              console.log(result)
              this.lv_RegistedPin = result.pin
              this.lv_RegistedBio = result.bio
              console.log('lv_RegistedPin = ' + this.lv_RegistedPin)
              console.log('lv_RegistedBio = ' + this.lv_RegistedBio)
              let lv_Vm = this
              let t
              if (lv_Vm.$commonUtil.getIsIOS()) {
                t = 'IOS'
              } else {
                t = 'AOS'
              }
              console.log('t = ' + t)
              lv_Vm.$commonUtil.getLoginServerTime(t).then( function (response) {
                lv_Vm.lv_todayTime = response
                lv_Vm.fn_UntyAthntLogin()
              })             
            })
            .catch(window.vue.error)
        } else {
          setTimeout( function () {
            getUserInfo()
          }, 200)
        }
      }
      getUserInfo()
    },
    /******************************************************************************
    * Function명 : fn_UserEnoInputFocusOut
    * 설명       : 사용자사번 인풋 포커스 아웃 시, 마스킹 값 보여주기
    ******************************************************************************/
    fn_UserEnoInputFocusOut () {
      console.log('##### fn_UserEnoInputFocusOut #####')
      this.fn_MaskingUserEno()
      this.lv_UserEnoAsterickFlag = false
    },
    /******************************************************************************
    * Function명 : fn_UserEnoInputFocusOn
    * 설명       : 사용자사번 인풋 포커스 인 시, 플레인 값 보여주기
    ******************************************************************************/
    fn_UserEnoInputFocusOn () {
      console.log('##### fn_UserEnoInputFocusOn #####')
      this.lv_UserEnoAsterickFlag = true
    },
    /******************************************************************************
    * Function명 : fn_MaskingUserEno
    * 설명       : 사용자사번 마스킹 처리(통합인증WEB기준)
    ******************************************************************************/
    fn_MaskingUserEno () {
      console.log('##### fn_MaskingUserEno #####')
      let length = this.lv_UserEno.length
      if (length === 10) { // 10자리
        this.lv_UserEnoAsterick = this.lv_UserEno.substr(0, 8) + '**'
      } else if (length === 7) { // 7자리
        this.lv_UserEnoAsterick = '000' + this.lv_UserEno.substr(0, 5) + '**'
        this.lv_UserEno = '000' + this.lv_UserEno
      } else if (length === 6) { // 6자리
        this.lv_UserEnoAsterick = '0000' + this.lv_UserEno.substr(0, 4) + '**'
        this.lv_UserEno = '0000' + this.lv_UserEno
      } else if (length === 5) { // 5자리
        this.lv_UserEnoAsterick = '10000' + this.lv_UserEno.substr(0, 3) + '**'
        this.lv_UserEno = '10000' + this.lv_UserEno
      } else if (length === 4) { // 4자리
        this.lv_UserEnoAsterick = '100000' + this.lv_UserEno.substr(0, 2) + '**'
        this.lv_UserEno = '100000' + this.lv_UserEno
      } else {
        this.lv_UserEnoAsterick = this.lv_UserEno
      }
    },
    /******************************************************************************
    * Function명 : fn_OpenMSPBC611P
    * 설명       : 간편인증 로그인 센터 팝업 페이지 이동
    ******************************************************************************/
    fn_OpenMSPBC611P () {
      console.log('##### fn_OpenMSPBC611P #####')
      this.$bcUtil.openFidoPopUpPage(this, 'MSPBC611P', {})
    },
    /******************************************************************************
    * Function명 : fn_OpenUntyAthntPage
    * 설명       : 통합인증 로그인 관리센터 바로가기
    ******************************************************************************/
    fn_OpenUntyAthntPage (param) {
      console.log('##### fn_OpenUntyAthntPage #####')
      this.$bcUtil.openUntyAthntPage(param)
    },
    /******************************************************************************
    * Function명 : fn_OpenUntyBottomSheet
    * 설명       : 통합인증 관리센터 선택 바텀 팝업 열기
    ******************************************************************************/
    fn_OpenUntyBottomSheet () {
      console.log('##### fn_OpenUntyBottomSheet #####')
      this.$refs.bottomSheetUntyAthnt.open()
    },
    /******************************************************************************
    * Function명 : fn_ErrorN01
    * 설명       : 사용자 권한이 없습니다
    ******************************************************************************/
    fn_ErrorN01 () {
      let _this = this
      this.lv_AlertPop = this.$bottomModal.open(MSPBottomAlert, {
        properties: {
          single: true,
          title: "알림",
          content: '아이디 또는 비밀번호를 잘못 입력하셨거나, 사랑On권한이 없는 아이디 입니다.\n아이디 또는 비밀번호를 다시 확인하세요.',
          title_pos_btn: "확인"
        },
        listeners: {
          onPopupConfirm: () => {
            console.log('onPopupConfirm call')
            _this.$bottomModal.close(_this.lv_AlertPop)
          },
          onPopupClose: () => {
            console.log('onPopupClose call')
            _this.$bottomModal.close(this.lv_AlertPop)
          }
        }
      }) 
    },
    /******************************************************************************
    * Function명 : fn_ErrorN03
    * 설명       : 비밀번호 5회 이상 틀린 경우
    ******************************************************************************/
    fn_ErrorN03 () {
      let _this = this
      this.lv_AlertPop = this.$bottomModal.open(MSPBottomAlert, {
        properties: {
          single: true,
          title: "비밀번호 초기화",
          content: '비밀번호 오류 초과로 잠김처리 되었습니다. 비밀번호 초기화를 해주세요.',
          title_pos_btn: "비밀번호 초기화"
        },
        listeners: {
          onPopupConfirm: () => {
            console.log('onPopupConfirm call')
            _this.$bottomModal.close(_this.lv_AlertPop)
            _this.$bcUtil.openUntyAthntPage('resetPw') // 비밀번호 초기화
          },
          onPopupClose: () => {
            console.log('onPopupClose call')
            _this.$bottomModal.close(this.lv_AlertPop)
          }
        }
      }) 
    },
    /******************************************************************************
    * Function명 : fn_ErrorN05
    * 설명       : 비밀번호 초기화 팝업으로 이동
    ******************************************************************************/
    fn_ErrorN05 () {
      let _this = this
      this.lv_AlertPop = this.$bottomModal.open(MSPBottomAlert, {
        properties: {
          single: true,
          title: "비밀번호 초기화",
          content: '통합인증 비밀번호가 만료되었습니다. 비밀번호 초기화를 해주세요',
          title_pos_btn: "비밀번호 초기화"
        },
        listeners: {
          onPopupConfirm: () => {
            console.log('onPopupConfirm call')
            _this.$bottomModal.close(_this.lv_AlertPop)
            _this.$bcUtil.openUntyAthntPage('resetPw') // 비밀번호 초기화
          },
          onPopupClose: () => {
            console.log('onPopupClose call')
            _this.$bottomModal.close(this.lv_AlertPop)
          }
        }
      }) 
    },
    /******************************************************************************
    * Function명 : fn_ErrorN06
    * 설명       : 아이디 잠김해제 팝업으로 이동
    ******************************************************************************/
    fn_ErrorN06 () {
      let _this = this
      this.lv_AlertPop = this.$bottomModal.open(MSPBottomAlert, {
        properties: {
          single: true,
          title: "아이디 잠김해제",
          content: '장기미사용으로 잠김처리 되었습니다.\n아이디 잠김해제를 해주세요',
          title_pos_btn: "아이디 잠김해제"
        },
        listeners: {
          onPopupConfirm: () => {
            console.log('onPopupConfirm call')
            _this.$bottomModal.close(_this.lv_AlertPop)
            _this.$bcUtil.openUntyAthntPage('unlockId') // 아이디 잠금 해제
          },
          onPopupClose: () => {
            console.log('onPopupClose call')
            _this.$bottomModal.close(_this.lv_AlertPop)
          }
        }
      }) 
    },
    /******************************************************************************
    * Function명 : fn_ErrorN07
    * 설명       : 관리자 아이디 팝업
    ******************************************************************************/
    fn_ErrorN07 (msg) {
      let _this = this
      this.lv_AlertPop = this.$bottomModal.open(MSPBottomAlert, {
        properties: {
          single: true,
          title: "관리자 아이디 잠김",
          content: msg,
          title_pos_btn: "확인"
        },
        listeners: {
          onPopupConfirm: () => {
            console.log('onPopupConfirm call')
            _this.$bottomModal.close(_this.lv_AlertPop)
          },
          onPopupCancel: () => {
            console.log('onPopupCancel call')
            _this.$bottomModal.close(_this.lv_AlertPop)
          }
        }
      }) 
    },
    /******************************************************************************
    * Function명 : fn_ErrorN08
    * 설명       : 최초 로그인 팝업 안내
    ******************************************************************************/
    fn_ErrorN08 () {
      let _this = this
      this.lv_AlertPop = this.$bottomModal.open(MSPBottomAlert, {
        properties: {
          single: true,
          title: "최초 로그인",
          content: "최초 로그인 사용자입니다.\n로그인관리센터에서 비밀번호 설정 후에 사용해주세요",
          title_pos_btn: "비밀번호 설정"
        },
        listeners: {
          onPopupConfirm: () => {
            console.log('onPopupConfirm call')
            _this.$bottomModal.close(_this.lv_AlertPop)
            _this.$bcUtil.openUntyAthntPage('firstPwd') // 비밀번호 설정
          },
          onPopupCancel: () => {
            console.log('onPopupCancel call')
            _this.$bottomModal.close(_this.lv_AlertPop)
          }
        }
      }) 
    },
    
    /******************************************************************************
    * Function명 : fn_UpdPasswd
    * 설명       : 통합인증 비밀번호 변경
    ******************************************************************************/        
    fn_UpdPasswd (userEno, cnt, type, imei) {
      this.lv_AlertPop = this.$bottomModal.open(MSPBottomAlert, {
        properties: {
          single: false,
          title: "비밀번호 초기화",
          content: '통합인증 비밀번호가 ' + cnt + '일 뒤에 만료됩니다. 비밀번호 초기화를 변경해주세요.',
          title_pos_btn: "비밀번호 초기화",
          title_neg_btn: "다음에 하기"
        },
        listeners: {
          onPopupConfirm: () => {
            console.log('onPopupConfirm call')
            this.$bottomModal.close(this.lv_AlertPop)
            this.$bcUtil.openUntyAthntPage('resetPw') // 비밀번호 변경
          },
          onPopupCancel: () => {
            console.log('onPopupCancel call')
            this.$bottomModal.close(this.lv_AlertPop)
          },
          onPopupClose: () => {
            console.log('onPopupClose call')
            this.$bottomModal.close(this.lv_AlertPop)
            this.$ToastManager.fn_ShowToast({ text: '로그인 잠김까지 ' + cnt + '일 남았습니다'})
            if (type) {
              // 간편인증 등록 유도 flag 추가
              if (!(this.lv_RegistedPin || this.lv_RegistedBio)) localStorage.setItem('fidoRegGuideFlag', true)  
              this.$MenuManager.fn_InitIntentListener({ userId: userEno })
            } else {
              this.$bcUtil.openFidoPopUpPage(this, 'MSPBC641P', { userEno: this.lv_UserEno, fidoRegType: '', loginFlag: false,  imei: imei})
            }     
          }
        }
      })

    },
    /******************************************************************************
    * Function명 : fn_ExitApp
    * 설명       : App 종료 액션
    ******************************************************************************/    
    fn_ExitApp () {
    // console.log('##### fn_ExitApp #####')
      this.lv_AlertPop = this.$bottomModal.open(MSPBottomAlert, {
        properties: {
          single: false,
          title: "알림",
          content: this.lv_MsgLogoutConfirm,
          title_pos_btn: "예",
          title_neg_btn: "아니오"
        },
        listeners: {
          onPopupConfirm: () => {
            console.log('onPopupConfirm call')
            this.$bottomModal.close(this.lv_AlertPop)
            window.fdpbridge.exec('logoutPlugin', {}, () => {}, () => {})
          },
          onPopupCancel: () => {
            console.log('onPopupCancel call')
            this.$bottomModal.close(this.lv_AlertPop)
          },
          onPopupClose: () => {
            console.log('onPopupClose call')
            this.$bottomModal.close(this.lv_AlertPop)
          }
        }
      })
    },
    /******************************************************************************
    * Function명 : fn_getMaskType
    * 설명       : iOS의 경우 다른 복호화 모듈을 호출하기 위한 Type 값 호출
    ******************************************************************************/        
    fn_getMaskType (type) {
      console.log('##### fn_getMaskType #####')
      this.lv_maskType = type
    },
    /******************************************************************************
    * Function명 : fn_CloseSubMeun
    * 설명       : 통합인증 로그인 관리 창 닫기
    ******************************************************************************/            
    fn_CloseSubMeun () {
      this.$refs.bottomSheetUntyAthnt.close()
    },
    /******************************************************************************
    * Function명 : fn_initMemory
    * 설명       : 통합인증 로그인 관리 창 닫기
    ******************************************************************************/            
    fn_initMemory () {
      let t_SendIntentObj = null
      let t_AppNm = ''

      try {
        t_AppNm = 'com.samsung.android.lool'  
        t_SendIntentObj = {packageName: t_AppNm, key: '', data: ''}
        window.fdpbridge.exec('intentExtraPlugin', t_SendIntentObj, () => {}, () => {})
      } catch (error) {
        let lv_AlertPop = this.$bottomModal.open(MSPBottomAlert, {
          properties: {
            single: true,
            title: "",
            content: '바로가기를 지원하지 않는 기기입니다.<br><br>기기에서 직접 검색을 통해 디바이스 케어를 완료해주세요.',
            title_pos_btn: "확인"
          },
          listeners: {
            onPopupConfirm: () => {
              this.$bottomModal.close(lv_AlertPop)
            },
            onPopupClose: () => {
              this.$bottomModal.close(lv_AlertPop)
            }
          }
        })
      }  
    },
    /******************************************************************************
    * Function명 : fn_VersionCheck
    * 설명       : 버전체크
    ******************************************************************************/
    async fn_VersionCheck () {
      console.log('##########fn_VersionCheck##########')
      try {
        // 불가능장비        
        let isMobile = this.getStore('deviceState').getters.getIsMobile
        if(isMobile) {
          // iOS 아닐 경우
          if (navigator.userAgent.indexOf("OIC-Agent iOS") > -1) {
            return false
          }

          /**
           * OIC-Agent Android 일 때, 
           * 1. To-Be 베타 앱(OIC-Agent Android)인 경우 또는
           * 2. To-Be 베타 앱버전(2.1.11)인 경우
           *  */
          // 팝업 표시 여부 및 다음에하기 버튼 표시 여부
          let pParams = {
            envId: 'DISP_NEXT_BTN_GUIDE',
          }            
          const isDispObj = await this.$commonUtil.selMgrCfg(this, pParams)   
          let isAlertPopup = isDispObj.some(item => item.envCntnt === 'PY')
          if (!isAlertPopup) return false
          const isToBeBetaApp = await this.fn_CheckToBeBetaApp()          
          console.log('OIC-Agent:',navigator.userAgent.indexOf("OIC-Agent Android") > -1)
          console.log('isToBeBetaApp:',isToBeBetaApp)
          if (navigator.userAgent.indexOf("OIC-Agent Android") > -1 && isToBeBetaApp) {
            let isDisp = isDispObj.some(item => item.envCntnt === 'Y')
            if (!isDisp) {
              // 다음에 하기 버튼을 안보이게 할 경우
              // 디바이스백키 비활성화
              window.fdpbridge.exec('backkeyCtrlPlugin', {value: false}, () => {}, () => {})
            }            
            this.fn_PopupDeleteWebView(isDisp)
            return true
          }
        } else {          
          return false // PC 테스트
          // 다음에 하기 버튼 표시 여부
          let pParams = {
            envId: 'DISP_NEXT_BTN_GUIDE',
          }            
          const isDispObj = await this.$commonUtil.selMgrCfg(this, pParams)          
          let isAlertPopup = isDispObj.some(item => item.envCntnt === 'PY')
          if (!isAlertPopup) return false
          let isDisp = isDispObj.some(item => item.envCntnt === 'Y')                     
          this.fn_PopupDeleteWebView(isDisp)
          return true         
        }        
      } catch (error) {
        console.error('fn_VersionCheck error : ', error)
      }
      
      return false
    },
    /******************************************************************************
    * Function명 : fn_CalculateDDay
    * 설명       : 디데이 계산
    ******************************************************************************/
    fn_CalculateDDay() {
      let rtn = ''
      if (this.lv_TargetDate) {
          const today = moment().startOf('day')
          const target = moment(this.lv_TargetDate).startOf('day')
          rtn = target.diff(today, 'days')
      }      
      return rtn <= 0 ? '[D-DAY] ' : rtn ? '[D-'+String(rtn)+'] ' : ''
    },
    /******************************************************************************
    * Function명 : fn_PopupDeleteWebView
    * 설명       : 웹뷰제거팝업 팝업
    ******************************************************************************/
    fn_PopupDeleteWebView (isSingleBtn) {
      let lv_Vm = this
      let dDay = this.fn_CalculateDDay()      
      let title = dDay +"태블릿,S24_사랑On 중단 안내"
      let bottomAlert = this.$bottomModal.open(MSPBottomAlert, {
        properties: {
          single: !isSingleBtn,
          title: title,          
          content: "<img src=\"/images/beta_mini_guide.png\" style=\"width:300px;\"><br>최신버전 사랑On을 설치 후 사용하세요!<br>※ 현재 사용중인 앱은 6.25일(화)부터 사용 불가합니다",
          title_pos_btn: "앱스토어 바로가기",
          title_neg_btn: "다음에하기"
        },
        listeners: {
          onPopupConfirm: () => {                        
            /**
             * 1. 앱스토어 이동
             * 2. 앱스토어가 없다면, 앱스토어 설치 url로 이동
             */            
            lv_Vm.fn_MoveAppStore()
            lv_Vm.$bottomModal.close(bottomAlert)
          },
          onPopupClose: () => {            
            console.log('fn_PopupDeleteWebView onPopupCancel')
            if(!(lv_Vm.Iv_SysInfo !== null && lv_Vm.Iv_SysInfo.isSysInfo)){
              lv_Vm.fn_GetVestPinUserInfo()
            }
            lv_Vm.$bottomModal.close(bottomAlert)
          }
        }
      })
    },
    /******************************************************************************
    * Function명 : fn_moveApp
    * 설명       : 앱 이동
    ******************************************************************************/
    fn_MoveAppStore () {
      if (this.lv_mobileYn) {
        let plgNm = 'intentExtraPlugin'
        let obj = {
          packageName: 'com.samsunglife.SLIAppStore', 
          key: '', 
          data: ''
        }
        // 1.앱스토어 화면 이동
        if (!this.fn_MoveApp(plgNm, obj)) {
          // 2. 앱스토어 화면이 없을 시에 앱스토어 설치 화면 이동          
          plgNm = 'intentPlugin'          
          obj = {
            apkName: process.env.NODE_ENV === 'production' ? 'http://apps.samsunglife.com' : 'http://apps.v.samsunglife.com',
            sendData: ''
          }
          if(!this.fn_MoveApp(plgNm, obj)) {
            console.log('intentPlugin error')
          }
        }
      }
    },
    /******************************************************************************
    * Function명 : fn_MoveApp
    * 설명       : 앱 이동
    * 파라미터    : 패키지명
    * 리턴
    ******************************************************************************/            
    fn_MoveApp (plgNm, obj) {            
      try {                        
        window.fdpbridge.exec(plgNm, obj, (msg) => {
          window.fdpbridge.exec('exitAppPlugin', {}, () => {}, () => {}) // 앱종료
          console.log('success: '+ msg)
          return true
        }, (err) => {          
          console.log('error: '+ err)
          return false
        })
      } catch (err) {
        return false
      }            
    },
    /******************************************************************************
    * Function명 : fn_CheckToBeBetaApp
    * 설명       : To-Be Beta 앱 체크 여부
    * 리턴       : true (To-Be Beta 앱) , false (To-Be 앱 Beta 아님)
    ******************************************************************************/            
    async fn_CheckToBeBetaApp () {
      let rtn = false
      const checkVersion = process.env.NODE_ENV === 'production' ? '2.1.11' : 
                       process.env.NODE_ENV === 'stage' ? '2.1.11' : 
                       process.env.NODE_ENV === 'development' ? '1.0.0' : ''
      await new Promise(function(resolve,reject){
        window.fdpbridge.exec('getVersionPlugin',''      
        ,(result)=>{
          if (result?.data) {
            let resultData = JSON.parse( result.data )
            let rsltData = (resultData?.versionName && resultData.versionName === checkVersion) ? true : false
            console.log('getVersionPlugin success version :', resultData.versionName, rsltData)
            resolve(rsltData)
          } else {
            console.log('getVersionPlugin failed !!')
            reject(false)
          }
        },(failed)=>{    
          console.log('getVersionPlugin failed !!')
          reject(false)
        })
      }).then(function(result){ // resolve call
        rtn = result
      }).catch(function(result){ // reject call
        rtn = result
      })
      return rtn
    },    
  }
}
</script>
<style scoped>
</style>